import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import moment from "moment";
import SVG from "react-inlinesvg";
import { sortCaret, toAbsoluteUrl } from "../../../_metronic/_helpers";
import { deleteApi, post } from "../../components/api";
import { showConfirmDialog, SweetAlert } from "../../utils/helper";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import CustomDateRangePicker from "../../components/DateRangePicker";
import VehicleComponent from "../../components/vehicleComponent";
import Loading from "../../components/LoadingComponent";
import debounceSearchParams from "../../components/debounce";
import {Search} from 'react-bootstrap-icons'

function VehiclePage({ history }) {
  const columns = [
    {
      dataField: "vehicleNo",
      text: "Vehicle No",
      sort: true,
      show: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "createdAt",
      text: "Date",
      formatter: (cell) => {
        return <p>{moment(cell).format("DD/MM/YYYY HH:mm:ss")}</p>;
      },
      sortCaret: sortCaret,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Edit Device</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => history.push(`/devices/edit/${row._id}`)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
            <> </>
            <OverlayTrigger
              overlay={
                <Tooltip id="products-delete-tooltip">Delete Device</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => openDeleteDialog(row._id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  const [pageData, setPageData] = useState([]);
  const [filters, setFilters] = useState({
    options: {
      page: 1,
      limit: 25,
    },
    search: "",
  });
  const [activeKey, setActiveKey] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  useEffect(() => {
    console.log("we are calling filters use effect");
    setIsLoading(true);
    fetchData();
  }, [filters.options.page || filters.options.limit]);

  useEffect(() => {
    console.log("we are calling search use effect");
    setIsLoading(true);
    const timeoutId = setTimeout(() => {
      debounceSearchParams(fetchData, 1000);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [filters.search, 1000]);

  const fetchData = async () => {
    try {
      const response = await post("/superadmin/vehicles", filters);
      console.log("response-superAdmin",response)
      setPageData(response.data.docs);
      setIsLoading(false);
      setIsBtnLoading(false);
    } catch (error) {
      // Handle error
      console.log(error);
      setIsLoading(false);
      setIsBtnLoading(false);
      setPageData([]);
    }
  };

  let handleSearchEvent = (value) => {
    setFilters((prevState) => ({
      ...prevState,
      search: value,
    }));
    setIsBtnLoading(true);
  };

  // update pagination options
  const updatepaginationOptions = (options) => {
    let tempFilters = { ...filters };
    tempFilters.options.page = options.page;
    tempFilters.options.limit = options.sizePerPage;
    setFilters(tempFilters);
  };

  //handle tab change
  const handleTabChange = (key) => {
    let tempFilters = { ...filters };
    if (key === "all") {
      tempFilters.query = [];
    } else {
      tempFilters.query.push({ status: key });
    }
    setFilters(tempFilters);
    setActiveKey(key);
  };

  // delete device
  const openDeleteDialog = async (id) => {
    const confirmed = await showConfirmDialog(
      "Delete!",
      "Are you sure you want to delete device?"
    );
    if (confirmed) {
      const response = await deleteApi(`/superadmin/device/${id}`);
      if (response.data.status === 200) {
        let tempPageData = { ...pageData };
        tempPageData.docs = pageData.docs.filter((item) => item._id !== id);
        SweetAlert(response.data.message, false);
        setPageData(tempPageData);
      } else {
        SweetAlert(response.data.message, true);
      }
    }
  };

  return (
    <Card>
      <CardHeader>
            <CardHeaderToolbar className="w-100">
            <div className="d-flex justify-content-between w-100">
            <div className="input-group w_40">
                <div className={`input-group-prepend input-group-prepend-search searchInputHeight`}>
                  <span className="input-group-text search-icon">
                    <span className="svg-icon svg-icon-sm">
                      <Search color="#4CBABA" size={8}/>
                    </span>
                  </span>
                </div>
                <input
                  placeholder="Search..."
                  type="text"
                  name="name"
                  className="form-control searchCustom"
                  style={{"borderColor":"#E4E6EF"}}
                  autoComplete="off"
                  onChange={(e)=> handleSearchEvent(e.target.value)}
                />
              </div>
                {/* {isBtnLoading ?
                <span style={{"padding-inline": "10px", "padding-top": "3px", "margin-left":"-20rem"}}><Spinner style={{"textAlign": "center"}} animation="border" variant="success" size="md" /></span>
                    : null} */}
            <CustomDateRangePicker isFullWidth={false} />
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      {!isLoading ? (
        <CardBody>
          <div className="row">
            {pageData.length > 0 ? (
              pageData.map((element, index) => (
                <VehicleComponent
                  data={element}
                  vehicleList={pageData}
                  id={index}
                />
              ))
            ) : (
              <p>No Vehicle List Found</p>
            )}
          </div>
        </CardBody>
      ) : (
        <Loading />
      )}
    </Card>
  );
}

export default VehiclePage;
